<template>
  <div>
    <!--Tabs-->
    <b-form-group class="col-sm-12 col-md-6 col-lg-3 px-0 mt-2">
      <b-card class="w-100" no-body>
        <flat-pickr
          v-model="selectedDate"
          class="form-control font-small-2"
          placeholder="Select Date"
          readonly="readonly"
        />
      </b-card>
    </b-form-group>
    <b-button
    @click="handleExport()"
    variant="primary"
    class="btn btn-primary mb-2"
    >Export</b-button
  >
    <Table
      :is-loading="isLoading"
      :table-data="tableData"
      :current-route="this.$route.name"
      :selected-date="selectedDate"
      @deleteRoute="deleteRoute"
    />
  </div>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import flatPickr from "vue-flatpickr-component";
import Table from "../../../components/Table.vue";
import exportFromJSON from "export-from-json";

export default {
  components: {
    Table,
    flatPickr,
    ValidationProvider,
  },

  data() {
    return {
      isLoading: true,
      selectedDate: new Date().toISOString().slice(0, 10),
      tableData: {
        columns: [
          {
            label: "Delivery ID",
            field: "route_id",
            tdClass: "text-center align-middle",
          },
          {
            label: "Order ID",
            field: "orders",
            tdClass: "text-center align-middle",
          },
          {
            label: "Driver ID",
            field: "driver.id",
            tdClass: "text-center align-middle",
          },
          {
            label: "Driver Name",
            field: "driver.name",
            tdClass: "text-center align-middle",
          },
          {
            label: "Delivery Date",
            field: "created_at",
            tdClass: "text-center align-middle",
          },
          {
            label: "Action",
            field: "action",
            tdClass: "text-center align-middle",
          },
        ],
        rows: [],
      },
    };
  },

  watch: {
    selectedDate(newDate, oldDate) {
      this.getRoutes(newDate);
    },
  },

  created() {
    this.getRoutes("");
  },

  methods: {
    getRoutes(value) {
      this.isLoading = true;
      this.$http.get(`routes/${this.selectedDate}`).then((response) => {
        if (response.data.status) {
          this.tableData.rows = [...response.data.routes];
        }
        this.isLoading = false;
      });
    },
    async exportRoute(Route) {
      const response = await this.$http.get(`export_route/${Route}`, {
        responseType: "blob",
      });
      const file = window.URL.createObjectURL(new Blob([response.data]));
      const anchor = document.createElement("a");
      anchor.href = file;
      anchor.setAttribute("download", `Route-${Route}-${Date.now()}.xlsx`);
      document.body.appendChild(anchor);
      anchor.click();
    },
    async handleExport() {
      const response = await this.$http.get(
        `route_list_export/${this.selectedDate}`,
        { responseType: 'blob' }
      );
      var fileURL = window.URL.createObjectURL(new Blob([response.data]));
      var fileLink = document.createElement('a');

      fileLink.href = fileURL;
      fileLink.setAttribute(
        'download',
        `Route_${this.selectedDate}.csv`
      );
      document.body.appendChild(fileLink);

      fileLink.click();
    },
    reassignDriver(Route) {
      this.form.route_id = Route;
      this.$http
        .get("get_driver_by_branch")
        .then((response) => (this.driverList = response.data.drivers));
      this.$refs["modal-3"].show();
    },
    deleteRoute(id, index) {
      this.$http.get(`delete_route?route_id=${id}`).then((response) => {
        if (response.status === 200) {
          this.$swal({
            icon: "success",
            title: "Deleted !",
            text: response.data.message,
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
          this.tableData.rows.splice(index, 1);
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
